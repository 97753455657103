export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value'
  }, {
    prop: 'online',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'rank',
    Object: 'value',
    label: '',
    width: '80'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value',
    width: '150',
  }]
}
